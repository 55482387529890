const Menu = [
  {
    heading: "menu",
    route: "/user",
    pages: [
      {
        heading: "Profile",
        route: "/user/show-profile",
        fontIcon: "fa-eye",
        svgIcon: "media/icons/duotune/general/gen019.svg",
      },
      {
        heading: "Update Profile Information",
        route: "/user/update-profile",
        fontIcon: "fa-user",
        svgIcon: "/media/icons/duotune/general/gen041.svg",
      },
      {
        heading: "Password",
        route: "/user/change-password",
        fontIcon: "fa-key",
        svgIcon: "/media/icons/duotune/general/gen026.svg",
      },
      {
        heading: "Signature",
        route: "/user/change-signature",
        fontIcon: "fa-signature",
        svgIcon: "/media/icons/duotune/general/gen026.svg",
      },
    ],
  },
];

export default Menu;
